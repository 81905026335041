
import { apiPost } from "@/lib/api_post";
import { Toast } from "@/lib/toast";
import { MyCandidate, MyPostDetail } from "@/models/post";
import { userStore } from "@/store/user";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  Ref,
  onMounted,
  nextTick,
} from "vue";
import { Reader } from "@/lib/html5-qrcode";
import { debounce, throttle } from "lodash";
import { parseJwt } from "@/lib/utils";
import { apiCompany } from "@/lib/api_gin";
export default defineComponent({
  name: "PostCheckInReader",
  props: {
    isRemoteVisible: { required: true, type: Boolean },
    postDetail: { required: true, type: MyPostDetail },
  },
  emits: ["close", "reloadList"],
  setup(props, { emit }) {
    const reader: Ref<Reader | undefined> = ref();
    const state = reactive({
      isVisible: false,
      isChecking: false,
      optionLadyList: [] as MyCandidate[],
      optionIds: [] as string[],
      isFetching: false,
    });
    const initReader = () => {
      reader.value = new Reader(
        "reader",
        { width: 250, height: 250 },
        (text: string) => {
          console.log("on reader: ", text);

          checkByReader(text);
        },
        (error) => {
          console.log("on reader error: ", error);

          return;
        }
      );
    };
    const checkByKeyboard = async () => {
      if (!state.isChecking) {
        state.isChecking = true;
        try {
          console.log("checkByKeyboard: ", state.optionIds);

          await check(state.optionIds);
        } catch (error) {
          undefined;
        } finally {
          state.isChecking = false;
        }
      }
    };
    const check = async (objectIds: string[]) => {
      try {
        console.log("check: ", objectIds);

        const res = await apiPost.createPostCheckInLog(
          { postId: props.postDetail.id, starObjectId: objectIds.flat()[0] },
          userStore.ginToken.value
        );
        Toast.info("報到成功");
        state.optionIds = [];
        emit("reloadList");
      } catch (error) {
        Toast.bugOccur();
      }
    };
    const close = () => {
      emit("close");
      reader.value?.stop();
    };
    watch(
      () => props.isRemoteVisible,
      async (val, oldVal) => {
        if (val !== oldVal) {
          state.isVisible = val;

          if (val) {
            await nextTick();
            initReader();
          } else {
            reader.value?.stop();
          }
        }
      },
      { immediate: true }
    );
    const checkByReader = throttle(async (input: string) => {
      if (!state.isChecking) {
        state.isChecking = true;
        let uid: number = parseJwt(input)["uid"];
        const uuid: string = parseJwt(input)["uuid"];
        try {
          await window.dog.pan.api.ValidateQRcode(uid, uuid, input);
          await check([uuid]);
        } catch (error) {
          console.log(error);
          Toast.info("無效ID");
        } finally {
          state.isChecking = false;
        }
      }
    }, 300);

    const fetchLadyList = debounce(async (search: string) => {
      state.optionLadyList = [];
      if (search) {
        state.isFetching = true;
        const uidRes = await apiCompany.getStarList(
          {
            page: 1,
            limit: 1000,
            uid: Number(search),
            status: true,
            isMine: false,
          },
          userStore.ginToken.value
        );
        state.optionLadyList = uidRes.getDataList().map(
          (e) =>
            new MyCandidate({
              uid: e.getUid(),
              objectId: e.getObjectId(),
              name: e.getDisplayName(),
              avatar: e.getAvatarUrl(),
            })
        );
        const phoneRes = await apiCompany.getStarList(
          {
            page: 1,
            limit: 1000,
            phone: search,
            status: true,
            isMine: false,
          },
          userStore.ginToken.value
        );
        state.optionLadyList.push(
          ...phoneRes.getDataList().map(
            (e) =>
              new MyCandidate({
                uid: e.getUid(),
                objectId: e.getObjectId(),
                name: e.getDisplayName(),
                avatar: e.getAvatarUrl(),
              })
          )
        );
        // remove duplications
        state.optionLadyList = [...new Set(state.optionLadyList)];
        state.isFetching = false;
      }
    }, 300);
    const onChange = () => {
      if (state.optionIds.length > 1) {
        state.optionIds.splice(0, 1);
      }
    };
    return {
      ...toRefs(state),
      checkByKeyboard,
      close,
      fetchLadyList,
      onChange,
    };
  },
});
