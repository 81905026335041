
import GoBackButton from "@/components/GoBackButton.vue";
import { apiPost } from "@/lib/api_post";
import { MyPostDetail } from "@/models/post";
import { userStore } from "@/store/user";
import { ColumnsType } from "ant-design-vue/lib/table";
import {
  defineComponent,
  reactive,
  onMounted,
  Ref,
  ref,
  toRefs,
  computed,
} from "vue";
import { useRoute } from "vue-router";
import { MyCheckIn } from "@/models/post";
import PostCheckInList from "@/components/PostCheckInList.vue";
import PostCheckInReader from "@/components/PostCheckInReader.vue";
import dayjs from "dayjs";
export default defineComponent({
  name: "ViewPostCheckIn",
  components: { GoBackButton, PostCheckInList, PostCheckInReader },
  setup() {
    const columns: ColumnsType = [
      {
        title: "人選名稱",
        key: "lady",
      },
      {
        title: "是否有報名",
        dataIndex: "isRegistered",
        key: "isRegistered",
        align: "center",
      },
      {
        title: "報到時間",
        dataIndex: ["checkInAt", "humanTime"],
        key: "time",
        align: "center",
      },
    ];
    const checkInList: Ref<MyCheckIn[]> = ref([]);
    const route = useRoute();
    const id: string = route.params.id as string;
    const state = reactive({
      isLoading: false,
      isListVisible: false,
      isReaderVisible: false,
    });
    const postDetail: Ref<MyPostDetail> = ref(new MyPostDetail());
    const init = async () => {
      state.isLoading = true;
      await getPostDetail();
      await getCheckInList();
      state.isLoading = false;
    };
    const getPostDetail = async () => {
      const res = await apiPost.getPostDetail(
        Number(id),
        userStore.ginToken.value
      );
      postDetail.value = new MyPostDetail(res.getInfo());
    };
    const getCheckInList = async () => {
      const res = await apiPost.getPostCandidateList(
        { postId: Number(id) },
        userStore.ginToken.value
      );
      checkInList.value = res.getCandidatesList().map((e) => new MyCheckIn(e));
    };
    const realCheckInList = computed(() => {
      return checkInList.value.filter((e) => e.isCheckIn);
    });
    const selectedCheckInList = computed(() => {
      return checkInList.value.filter((e) => e.isSelected);
    });
    onMounted(async () => {
      init();
    });
    const showList = () => {
      state.isListVisible = true;
    };
    const closeList = () => {
      state.isListVisible = false;
    };
    const showReader = () => {
      state.isReaderVisible = true;
    };
    const closeReader = () => {
      state.isReaderVisible = false;
    };
    return {
      ...toRefs(state),
      postDetail,
      columns,
      realCheckInList,
      checkInList,
      MyCheckIn,
      closeList,
      showList,
      selectedCheckInList,
      showReader,
      closeReader,
      getCheckInList,
    };
  },
});
